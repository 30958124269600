import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface UaaSState {
  execution?: object;
  flow?: object;
  productId?: string;
  affiliateId?: string;
}

const initialState: UaaSState = {};

const uaasSlice = createSlice({
  name: 'uaas',
  initialState,
  reducers: {
    setExecution(state, action: PayloadAction<UaaSState['execution']>): void {
      state.execution = action.payload;
    },
    setFlow(state, action: PayloadAction<UaaSState['flow']>): void {
      state.flow = action.payload;
    },
    setProductId(state, action: PayloadAction<UaaSState['productId']>): void {
      state.productId = action.payload;
    },
    setAffiliateId(
      state,
      action: PayloadAction<UaaSState['affiliateId']>
    ): void {
      state.affiliateId = action.payload;
    },
  },
});

export const { setExecution, setFlow, setProductId, setAffiliateId } =
  uaasSlice.actions;

export default uaasSlice.reducer;
