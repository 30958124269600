import { DirectDebitMutationPayload } from './loans/queries';
import { UserTypes } from 'kennek/interfaces/accounts';
import { MetaData } from 'kennek/interfaces/documentManagement';

export enum UNDERWRITING_STATUS {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  READY_FOR_REVIEW = 'READY_FOR_REVIEW',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  AWAITING_RESPONSE = 'AWAITING_RESPONSE',
  IN_REVIEW = 'IN_REVIEW',
}

export const STATUS: Record<UNDERWRITING_STATUS, string> = Object.freeze({
  '': 'All',
  PENDING: 'Pending',
  IN_PROGRESS: 'In progress',
  READY_FOR_REVIEW: 'Ready for review',
  ACCEPTED: 'Approved',
  REJECTED: 'Declined',
  AWAITING_RESPONSE: 'Awaiting response',
  IN_REVIEW: 'In review',
  REMOVED: 'Deleted',
});

export const STATUS_COLORS: Record<UNDERWRITING_STATUS, string> = Object.freeze(
  {
    PENDING: 'neutral',
    READY_FOR_REVIEW: 'warning',
    AWAITING_RESPONSE: 'warning',
    IN_PROGRESS: 'neutral',
    ACCEPTED: 'success',
    REJECTED: 'error',
    IN_REVIEW: 'warning',
  }
);

export interface UnderwritingRequest {
  executionId: string;
  email: string;
  borrowerName: string;
  status: UNDERWRITING_STATUS;
  invitedOn: string;
  applicationType: string;
  userActivated: boolean;
  userVerified: boolean;
  stage?: number;
  stageTitle?: string;
  actorRole: UserTypes;
  deleted: boolean;
}

export interface UnderwritingRequestTable extends UnderwritingRequest {
  isBorrower?: boolean;
}

export interface UnderwritingRequestResponse {
  data: UnderwritingRequest[];
  totalCount: number;
}

export interface UnderwritingRequestInput {
  flowId: string;
  productId?: string;
  affiliateId?: string;
}

export interface UnderwritingRequestPayload extends UnderwritingRequestInput {
  branchEncodedKey: string;
}

export enum DirectDebitIntegrationStatus {
  NotAvailable = 'NOT_AVAILABLE',
  ReadyToActivate = 'READY_TO_ACTIVATE',
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  UnderReview = 'UNDER_REVIEW',
  Cancelled = 'CANCELLED',
}

export interface DirectDebitStatus {
  status: DirectDebitIntegrationStatus;
  loanId?: string;
  rejectionReason?: string;
}

export interface DMUnderwritingDocument {
  executionId: string;
  fieldName: string;
  folder: string;
  folderId?: string;
  storageKey: string;
  name: string;
  originalFilename: string;
  mimeType: string;
  size: number;
  loanEncodedKey?: string;
  tags?: string[];
  isDraft?: string;
  metadata?: MetaData;
  uploadedByName: string;
  uploadedByEmail?: string;
}

export interface DirectDebitData extends DirectDebitMutationPayload {
  executionId: string;
}

export interface CodatCompanyRedirect {
  redirectUrl: string;
  companyId: string;
}

export interface CodatIntegrationItem {
  /**
   * A unique 4-letter key to represent a platform in each integration
   */
  key: string;
  /**
   * Static url for integration's logo
   */
  logoUrl: string;
  /**
   * Name of integration.
   */
  name: string;
  /**
   * A source-specific ID used to distinguish between different sources originating from the same data connection.
   * In general, a data connection is a single data source.
   * However, for TrueLayer, sourceId is associated with a specific bank and has a many-to-one relationship with the integrationId.
   */
  sourceId: string;
  /**
   * The type of platform of the connection.
   */
  sourceType: CodatSourceType;
  /**
   * A Codat ID representing the integration.
   */
  integrationId: string;
  /**
   * True if the integration is to an application installed and run locally on an SMBs computer.
   */
  isOfflineConnector: boolean;
  /**
   * if the integration is currently in beta release.
   */
  isBeta: boolean;
  /**
   * The name of the data provider.
   */
}

export interface UnderwritingRepaymentsQuery {
  status?: UnderwritingRepaymentStatus;
  loanId?: string;
  fromDate?: string;
  dateTo?: string;
  consolidate?: boolean;
}

export enum UnderwritingRepaymentStatus {
  ALL = 'ALL',
  PENDING = 'PENDING',
  LATE = 'LATE',
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  GRACE = 'GRACE',
}

export enum CodatSourceType {
  Accounting = 'Accounting',
  Banking = 'Banking',
  Commerce = 'Commerce',
  Other = 'Other',
  Unknown = 'Unknown',
}

export interface ExecutionServicingRedirectionDetails {
  redirectionType: 'DETAILS' | 'ONBOARDING';
  loanId?: string;
  executionId?: string;
  borrowerEmail?: string;
}

export interface RiskAnalysisRequest {
  executionId: string;
  companyNumber: string;
  originatorEncodedKey: string;
  folderName: string;
}

export interface RiskAnalysisResponse {
  source: 'PRIVATE' | 'PUBLIC';
  id: string;
  fileDetails: ReportFileDetails;
}

export interface ReportFileDetails {
  id: string;
  size: number;
  storageKey: string;
  originalFilename: string;
}
