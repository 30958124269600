// TODO: Unify snackbar messages for both apps in one single file
import { LabelsConfig } from 'kennek/interfaces/labelsConfig';

export const SNACKBAR_CHANGE_PASSWORD = {
  CHANGED_TITLE: 'The new password has been saved!',
  CHANGED_CONTENT: 'Your password has been updated.',
  CHANGE_FAILED_TITLE: 'Failed to change password',
  CHANGE_FAILED_CONTENT:
    'There was a problem with the service or internet connection.',
  CHANGE_WRONG_PASSWORD: 'The password that you entered is incorrect',
  SET: 'Your password has been set!',
  SET_CONTENT: 'Your password has been saved on your account.',
  CODE_SENT: 'Code verification sent.',
  CODE_SENT_CONTENT: 'A 6 digit verification has been sent to your phone.',
};

export const SNACKBAR_CHANGE_PHONE = {
  UPDATE_FAILED: 'Failed to change Phone',
  UPDATE_FAILED_CONTENT: 'Sorry, something went wrong. Please try again.',
  SMS_FAILED: 'Failed to send verification code',
  SMS_FAILED_CONTENT: 'Sorry, something went wrong. Please try again.',
  CODE_SENT: 'Code verification sent.',
  CODE_SENT_CONTENT: 'A 6 digit verification has been sent to your phone.',
  CODE_WRONG: 'Failed to verify code',
  CODE_EXPIRED: 'The code has expired',
  CODE_EXPIRED_CONTENT: 'Please request a new code',
  SUCCESS: 'Your new phone has been set!',
};

export const SNACKBAR_2FA = {
  ENABLE_2FA_TITLE: 'Enable 2FA',
  DISABLE_2FA_TITLE: 'Disable 2FA',
  '2FA_CONFIGURATION_SUCCESS': 'Saved successfully!',
  '2FA_CONFIGURATION_ERROR':
    'Oops, something went wrong. Please, try again later.',
  SET_2FA_SUCCESS: 'Your two factor authentication has been set.',
  SET_2FA_ERROR:
    "We couldn't set the two factor authentication, please try again.",
};

export const SNACKBAR_DOCUMENT_MANAGEMENT = {
  DOWNLOAD_FILE_SUCCESS: 'Your documents were successfully downloaded',
  DOWNLOAD_FILE_FAILED: 'Failed to download the document',
  FILE_RESTORED: 'The file has been restored',
  FILE_RESTORED_FAILED: 'Failed to restore file',
  FOLDER_RESTORED: 'The folder has been restored',
  FOLDER_RESTORED_FAILED: 'Failed to restore folder',
  FILES_UPLOADED: ' file(s) successfully uploaded!',
  FILE_DELETED: 'The file has been deleted',
  FILE_DELETE_NOT_FOUND:
    'The file that you are trying to delete was not found.',
  FILE_DELETED_FAILED: 'Sorry, something went wrong. Please try again.',
  FOLDER_FAILED: 'Failed to fetch folders',
  FOLDER_CREATE_FAILED: 'Failed to create folder',
  FOLDER_CREATE_SUCCESS: 'New folder created!',
  FOLDER_EDIT_NAME_FAILED: 'Failed to edit folder name',
  FOLDER_EDIT_NAME_SUCCESS: 'Folder name successfully modified',
  FOLDER_EDIT_ACCESS_FAILED: 'Failed to edit folder access',
  FOLDER_EDIT_ACCESS_SUCCESS: 'Access to folder updated!',
  FILE_EDIT_NAME_SUCCESS: 'File name successfully modified',
  FILE_EDIT_NAME_FAILED: 'Failed to edit file name',
  FOLDER_DELETE_SUCCESS: 'The folder has been deleted',
  FOLDER_DELETE_NOT_FOUND:
    'The folder that you are trying to delete was not found.',
  FOLDER_DELETED_FAILED: 'Sorry, something went wrong. Please try again.',
};

export const SNACKBAR_ONBOARDING = {
  // BORROWER ONBOARDING
  BORROWER_CREATION_TITLE: ({ borrowerLower }: LabelsConfig) =>
    `The new ${borrowerLower} has been saved!`,
  BORROWER_CREATION_CONTENT: ({ borrowerLower }: LabelsConfig) =>
    `Your ${borrowerLower} has been updated.`,
  BORROWER_CREATION_FAILED_TITLE: ({ borrowerLower }: LabelsConfig) =>
    `Failed to save ${borrowerLower}`,
  BORROWER_CREATION_FAILED_CONTENT_BAD_REQUEST: 'Failed to save user',
  BORROWER_CREATION_FAILED_CONTENT_DUPLICATED_USER_TITLE:
    'Please provide a different email address',
  BORROWER_CREATION_FAILED_INVALID_COMPANY:
    'There was a problem creating the company - please check the details and try again',
  BORROWER_CREATION_FAILED_DUPLICATE_COMPANY_TITLE: ({
    borrowerLower,
  }: LabelsConfig) => `Failed to save ${borrowerLower}`,
  BORROWER_CREATION_FAILED_DUPLICATE_COMPANY_MESSAGE: ({
    borrowerLower,
  }: LabelsConfig) =>
    `This ${borrowerLower} already has a loan associated. There can be only one loan per ${borrowerLower}.`,
  BORROWER_CREATION_FAILED_DUPLICATE_COMPANY:
    'The company you are trying to create already exists in the system',
  BORROWER_CREATION_FAILED_CONTENT_INTERNAL_ERROR:
    'There was a problem with the service. Please try again.',
  BORROWER_ONBOARDING_COMPLETED: ({ borrowerUpper }: LabelsConfig) =>
    `Completed ${borrowerUpper} Onboarding.`,
  BORROWER_ONBOARDING_FAILED: 'Failed to complete Onboarding.',
  BORROWER_ONBOARDING_LOAN_SCHEDULED_FOR_DISBURSEMENT:
    'Loan has been scheduled for disbursement!',
  // COMPANY
  COMPANY_UPDATE_SUCCESS: 'Company was updated',
  COMPANY_UPDATE_FAILED_TITLE: ({ borrowerLower }: LabelsConfig) =>
    `Failed to update ${borrowerLower}.`,
  COMPANY_UPDATE_FAILED_COMPANY_MISMATCH:
    'The company is not associated with the user.',
  COMPANY_UPDATE_FAILED_USER_NOT_FOUND: ({ borrowerUpper }: LabelsConfig) =>
    `${borrowerUpper} user not found.`,
  COMPANY_FAILED_INTERNAL_ERROR:
    'There was a problem with the service. Please try again.',
  // USER
  USER_UPDATED: 'User data has been updated.',
  USER_UPDATE_FAILED_NOT_FOUND: 'The user could not be bound',
  USER_UPDATE_FAILED: 'There was a problem with the service. Please try again.',
  // LOAN ONBOARDING
  LOAN_CREATION_TITLE: 'The new Loan has been saved!',
  LOAN_CREATION_FAILED_TITLE: 'Failed to save new Loan.',
  LOAN_UPDATE_TITLE: 'The Loan was updated!',
  LOAN_UPDATE_FAILED_TITLE: 'Failed to update the Loan.',
  LOAN_IMPORT_SCHEDULE_FAILED: 'Failed to upload repayment schedule file.',
  LOAN_IMPORT_SCHEDULE_FAILED_FEES:
    'Failed to upload repayment schedule file. Fees are not allowed.',
  // FILES MANAGEMENT ONBOARDING
  FILE_UPLOAD: 'The file has been uploaded',
  FILE_UPLOAD_FAILED: 'Sorry, something went wrong. Please try again.',
  FILE_UPLOAD_WRONG_EXTENSION:
    'The type of file you are trying to upload is not supported. Supported file types: PDF, Excel, Doc or image files.',
  FILE_UPLOAD_MAX_SIZE_EXCEED:
    'The file that you are trying to upload exceeds the maximum size. Please upload a file smaller than 10MB.',
  // REPORTING
  REPORTING_SUCCESS: 'Report has been created',
  REPORTING_FAILED: 'Failed to create Report',
  REPORTING_UPDATE_FAILED: 'Failed to update Reports',
  FINANCIAL_YEAR_END_UPDATED: 'Financial year end date has been updated',
  // MISC
  LOAN_ASSETS_UPDATE_SUCCESS: 'Loan assets created successfully',
  LOAN_ASSETS_UPDATE_FAILED: 'Assets creation error',
  // CUSTOM INFORMATION
  CUSTOM_INFORMATION_SUCCESS: 'Custom information has been saved!',
  CUSTOM_INFORMATION_FAILED_SAVE: 'Failed to save custom information',
  CUSTOM_INFORMATION_FAILED_GET: 'Failed to get custom information settings',
  CUSTOM_INFORMATION_FAILED_GET_DATA: 'Failed to get custom information data',
  CUSTOM_INFORMATION_FAILED_GET_USERS:
    'Failed to get users for business development manager',
  CUSTOM_INFORMATION_FAILED_GET_UNITS:
    'Failed to get units for custom information',
};

export const SNACKBAR_RESTRUCTURE = {
  // LOAN INFO
  LOAN_INFO_API: 'Failed to get the loan information.',
  LOAN_EDIT_TITLE: 'The new Loan has been edited!',
  LOAN_EDIT_FAILED_TITLE: 'Failed to edit the Loan.',
  CANNOT_GET_DEDUCTED_FEES_VALUE: 'Cannot get interest grace amount',
};

export const SNACKBAR_PAYOFF = {
  PAYOFF_SUCCESS: 'The loan has been successfully repaid in full.',
  PAYOFF_FAILED: 'There was a problem to close the Loan. Please try again',
  ALREADY_CLOSED: 'The Loan was already closed',
};

export const SNACKBAR_NOTIFICATIONS = {
  UPDATE_FAILED_TITLE: 'There was an error trying to update notifications.',
  UPDATE_FAILED_CONTENT: 'Please try again later.',
};

export const SNACKBAR_TASKS = {
  // Get
  GET_FAILED_TITLE: 'Failed to get tasks.',
  GET_FAILED_CONTENT: 'Please try again later.',
  // Update
  UPDATE_SUCCESS_TITLE: {
    REJECT: 'The document has been rejected',
    APPROVE: 'The document has been approved',
    COMPLIANT: 'The task was marked as Compliant and moved to closed.',
    NOT_COMPLIANT: 'The task was marked as Not compliant and moved to closed.',
    UPLOAD: 'The document has been sent',
    DEFAULT: 'The task has been updated',
  },
  UPDATE_FAILED_TITLE: 'Failed to save the task.',
  UPDATE_FAILED_CONTENT: 'Please try again later.',
  // Upload
  FILE_UPLOAD: 'The file has been uploaded',
  UPLOAD_FAILED_ALREADY_UPLOADED: 'File already uploaded for this task.',
  UPLOAD_WRONG_EXTENSION:
    'The type of file you are trying to upload is not supported. Supported file types: PDF, Excel, Doc or image files.',
  UPLOAD_MAX_SIZE_EXCEED:
    'The file that you are trying to upload exceeds the maximum size. Please upload a file smaller than 10MB.',
  UPLOAD_CORRUPTED_FILE:
    'Document is corrupted and failed to upload. Please try again.\nIf the problem persists please contact support at help@kennek.io.',
  UPLOAD_FAILED_TITLE: 'Failed to upload tasks.',
  UPLOAD_FAILED_CORRUPTED: 'Corrupted file',
  UPLOAD_CANCELED: 'File upload was canceled:',
  // Download
  DOWNLOAD_SUCCESS: 'The file has been downloaded',
  DOWNLOAD_IN_PROGRESS: 'Downloading, please wait...',
  DOWNLOAD_NOT_FOUND: 'Could not find the file.',
  DOWNLOAD_FAILED: 'Failed to download the document',
};

export const SNACKBAR_SERVICING = {
  RESEND_INVITE_SUCCESS: 'Invitation has been sent.',
  RESEND_INVITE_FAILED_TITLE: 'Failed to send invite',
  RESEND_INVITE_ALREADY_ACTIVATED: 'Account already Activated',
  RESEND_INVITE_FAILED_CONTENT:
    'Oops, something went wrong. Please, try again later',
};

export const SNACKBAR_USER_MGMT = {
  CREATED_USER_SUCCESS: 'Invitation Sent',
  CREATED_SUCCESS_CONTENT: `The invitation has been sent`,
  CREATE_FAILED_TITLE: 'Failed to create user',
  CREATE_ALREADY_EXISTS: 'User already exists',
  CREATE_FAILED_CONTENT: 'Please try again later',
  RESEND_USER_SUCCESS: 'Invitation Sent',
  RESEND_SUCCESS_CONTENT: `The invitation has been sent`,
  RESEND_FAILED_TITLE: 'Failed to resend invitation',
  RESEND_FAILED_CONTENT: 'Please try again later',
  DELETE_SUCCESS_TITLE: 'User Deleted',
  DELETE_SUCCESS_CONTENT: 'The user has been deleted',
  DELETE_FAILED: 'Failed to delete user',
  DELETE_FAILED_CONTENT: 'Please try again later',
  UPDATE_SUCCESS: 'User Updated',
  UPDATE_SUCCESS_CONTENT: 'Details have been modified',
  UPDATE_FAILED: 'Failed to update user',
  UPDATE_FAILED_CONTENT: 'Please try again later',
  SEND_RESET_PASSWORD_SUCCESS: 'Reset password sent',
  SEND_RESET_PASSWORD_SUCCESS_CONTENT: `The reset password email has been sent`,
  SEND_RESET_PASSWORD_FAILED_TITLE: 'Failed to send reset password',
  SEND_RESET_PASSWORD_FAILED_CONTENT: 'Please try again later',
};

export const SNACKBAR_INVESTOR = {
  CREATE_FAILED: 'Failed to create an investor',
  CREATE_FAILED_CONTENT: 'Oops, something went wrong. Please, try again later',
  CREATE_SUCCESS: 'Investor created Successfully',
  LENDER_SUCCESS: 'Lender of Record created successfully',
  LENDER_FAILED: 'Failed to create Lender of Record',
};

export const SNACKBAR_SUMMARY = {
  LOAN_SUMMARY_API: 'GET LOAN SUMMARY ERROR',
};

export const SNACKBAR_TRANSACTION = {
  ADJUST_TRANSACTION_ERROR: 'Failed to adjust transaction',
  ADJUST_TRANSACTION_SUCCESS: 'The transaction has been adjusted.',
  DOWNLOAD_SUCCESS: 'The transaction statement has been downloaded',
  DOWNLOAD_FAILED: 'Failed to download the transaction statement',
};

export const SNACKBAR_EARLY_PAYMENT = {
  EARLY_PAYMENT_ON_DUE_DATE_SUCCESS:
    'The payment has been reconciled on due date',
  EARLY_PAYMENT_ON_PAYMENT_DATE_SUCCESS:
    'The payment has been reconciled on payment date',
  EARLY_PAYMENT_FAILED: 'The payment reconciliation failed',
};

export const SNACKBAR_PAYMENT_ALLOCATION_STRATEGY = {
  PAYMENT_ALLOCATION_INTEREST_SUCCESS:
    'The payment has been allocated to Interest',
  PAYMENT_ALLOCATION_PRINCIPAL_SUCCESS:
    'The payment has been allocated to Principal',
  PAYMENT_ALLOCATION_FAILED: 'The payment allocation failed',
};

export const SNACKBAR_LOAN_APPLICATION = {
  UPLOAD_WRONG_FILE_EXTENSION: (supportedFileType: string) =>
    `The type of file you are trying to upload is not supported. Supported file types: ${supportedFileType}.`,
  UPLOAD_FILE_FAILED: 'File upload failed',
  UPLOAD_MAX_FILE_NUMBER: (maxFileNumber: number) =>
    `The maximum number of files (${maxFileNumber}) has been exceeded.`,
  FILE_UPLOADED: 'The file has been uploaded',
};

export const SNACKBAR_UNDERWRITING = {
  LOAN_APPLICATION_CREATE_FAILED: 'Failed to create loan application',
  SAVE_AS_DRAFT: 'Your details have been saved',
  CHANGES_SAVE_SUCCESS: 'The changes have been saved.',
  SECTION_UPDATED_SUCCESS: 'Section updated successfully',
  CHANGES_SAVE_FAILED: 'An error has occured. Please try again.',
  LOAN_MIGRATION_SUCCESS: 'Loan data migrated.',
  LOAN_MIGRATION_FAILED: 'Unable to migrate data.',
  LOAN_ACCEPTED_SUCCESS: 'Basic Loan Information has been accepted.',
  LOAN_ACCEPTED_FAILED: 'An error has occured. Please try again.',
  LOAN_APPLICATION_DECLINE_SUCCESS: 'Loan Application declined',
  LOAN_APPLICATION_DECLINE_FAILED: 'Failed to decline loan application',
  LOAN_CREATION_FAILED: 'Loan creation failed',
  GET_REPAYMENT_SCHEDULE_FAILED: 'Unable to fetch repayment schedule',
  LOAN_REDIRECTION_FAILED: 'Unable to show loan details. Please try again.',
  LOAN_APPLICATION_DELETE: 'Loan application deletion',
  LOAN_APPLICATION_DELETE_SUCCESS_CONTENT: 'Loan Application has been deleted',
  LOAN_APPLICATION_DELETE_FAILED_CONTENT: 'Failed to delete loan application',
};

export const SNACKBAR_REPAYMENT_SCHEDULE = {
  REPAYMENT_SCHEDULE_DOWNLOAD_SUCCESS:
    'The repayment schedule has been downloaded',
  REPAYMENT_SCHEDULE_DOWNLOAD_FAILED:
    'Failed to download the repayment schedule',
};

export const SNACKBAR_LOAN_STATEMENT = {
  LOAN_STATEMENT_DOWNLOAD_SUCCESS: 'The loan statement has been downloaded',
  LOAN_STATEMENT_DOWNLOAD_FAILED: 'Failed to download the loan statement',
};

export const SNACKBAR_PAYMENT = {
  PAYMENT_CONFIRMATION_SUCCESS: 'Payment confirmation was successful',
  PAYMENT_CONFIRMATION_FAILED: 'Failed to confirm payment',
  PAYMENT_RECONCILE_IS_BEING_PROCESSED: 'Payment is being processed',
  PAYMENT_RECONCILE_FAILED: 'Failed to reconcile a payment',
  PAYMENT_RECONCILE_FAILED_DEFAULT: 'Oops, something went wrong',
  PAYMENT_RECONCILE_SUCCESS: 'The payment has been reconciled',
};

export const SNACKBAR_DIRECT_DEBIT_SCHEDULE = {
  SCHEDULE_DIRECT_DEBIT_FAILED: 'Error. Cannot schedule the Direct Debit.',
};

export const SNACKBAR_FACILITY = {
  CREATE_FAILED: 'Failed to create a facility',
  CREATE_FAILED_CONTENT: 'Oops, something went wrong. Please, try again later',
  CREATE_SUCCESS: 'Facility created successfully',
};

export const SNACKBAR_TRANCHES = {
  EDIT_SUCCESS: 'The loan tranches has been edited',
  EDIT_FAILED: 'Failed to edit the loan tranches ',
};

export const SNACKBAR_LOAN_DETAILS = {
  MANUAL_FEES_NOT_CONFIGURED: "Couldn't find manual fee configuration.",
  MANUAL_FEES_NOT_AVAILABLE: 'Cannot add fees to closed loan.',
};

export const REPAYMENT_SCHEDULE_SETTINGS = {
  SUCCESSFUL_UPDATE: 'Repayment schedule settings updated',
  FAILED_UPDATE: 'Cannot update repayment schedule settings',
};

export const CONFIG_LABELS_SETTINGS = {
  EDIT_LABEL_SUCCESS: 'Label name updated',
  EDIT_LABEL_FAILED: 'Failed to update label name',
};

export const CODAT = {
  CODAT_CONNECTED_SUCCESSFULLY:
    'You have been connected with codat successfully',
  CODAT_CONNECTION_ERROR: 'An unexpected error occurred. Please try again.',
};

export const SNACKBAR_RISK_ANALYSIS = {
  RISK_ANALYSIS_DOWNLOADED_SUCCESSFULLY:
    'The risk analysis report has been downloaded.',
  RISK_ANALYSIS_PRIVATE_COULD_NOT_BE_RETRIEVED:
    'Private report could not be retrieved. Public report downloaded.',
  RISK_ANALYSIS_GENERATING_ERROR:
    'Error generating risk analysis report. Please try again.',
  RISK_ANALYSIS_DOWNLOADING_ERROR:
    'Error downloading risk analysis report. Please try again.',
  RISK_ANALYSIS_DOWNLOADED_WARNING:
    'Private report could not be retrieved. Public report downloaded',
  RISK_ANALYSIS_FILE_UPLOAD_ERROR:
    'There was an error during upload document to datastore',
};

export const FILE_VALIDATION = {
  FILE_UPLOAD_FAILED_XLSX_EXTENSION:
    'The file extension is incorrect. Select a file with the xlsx extension.',
};

export const SNACKBAR_INVESTMENT_FUNDING_METHODS = {
  CANNOT_FETCH_FUNDING_METHODS_TITLE:
    'Cannot fetch investment funding methods.',
  CANNOT_FETCH_FUNDING_METHODS_FEES_SETTINGS_TITLE:
    'Cannot fetch investment funding methods fees configuration.',
  FUNDING_METHOD_CREATE_SUCCESS_TITLE: 'New funding method created.',
  FUNDING_METHOD_CREATE_ERROR_TITLE: 'Cannot create funding method.',
  FUNDING_METHOD_UPDATE_SUCCESS_TITLE: 'Funding method updated.',
  FUNDING_METHOD_UPDATE_ERROR_TITLE: 'Cannot update funding method.',
  FUNDING_METHOD_DELETE_SUCCESS_TITLE: 'Funding method removed.',
  FUNDING_METHOD_DELETE_ERROR_TITLE: 'Cannot remove funding method.',
};
